

.desc-title-container {
    display: flex;
    align-items: center;
    gap: 1rem;

   
}

.desc-title-container img {
    max-height: 2.5rem;
    width: 3rem;
}

.desc-title {
    margin-bottom: .4rem;
    font-size: 2rem;
    font-weight: 600;
    text-decoration: underline;
    opacity: .8;
}

.help-desc {
    max-width: 38rem;
    padding-left: 4rem;

   
}

.alt-img{
    width: 3rem;
    height: 40px;
}

@media only screen and (max-width: 1380px) {
  
    
    .desc-title-container img {
        max-height: 2.3rem;
    }
    
    .desc-title {
        margin-bottom: 0;
        font-size: 1.4rem;
        font-weight: 600;
        text-decoration: underline;
    }

}