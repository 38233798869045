.description-list {
    display: flex;
    flex-direction: column;
    z-index: 1;
    gap: 0rem;
    padding-left: 2rem;
    height: 100vh;
    margin-top: 3rem;
}

