@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@600;700;800;900&display=swap');

:root {
    --prymairy: #161513;
    --secondary: #8D8983;
    --light-text: #D9D9D9;
    --medium-text: #8A8A8A;
    --dark-text: #6B6761;
    --black: black;
}

* {
    font-family: 'Inter', sans-serif;
    color: var(--light-text)
}

body {
  margin: 0;

}

*::-webkit-scrollbar {
  width: 10px;
  width: 10px;
}
*::-webkit-scrollbar-track {
  background-color: var(--prymairy);
}

*::-webkit-scrollbar-track:hover {
  background-color: var(--black);
}

*::-webkit-scrollbar-track:active {
  background-color: var(--black);
}

*::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: var(--dark-text);
}

*::-webkit-scrollbar-thumb:hover {
  background-color: var(--secondary);
}

*::-webkit-scrollbar-thumb:active {
  background-color: var(--secondary);
}

