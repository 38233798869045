.footer {
    display: flex;
    position: absolute;
    bottom: 2rem;
    right: 2rem;
    opacity: .3;
    font-size: 1.1rem;
    z-index: 999;
    transition: 250ms;
    padding: 1rem;
    white-space: nowrap;
    gap: 1rem;
}

.footer-links {
    display: flex;
    gap: 1rem
}

.footer a {
    text-decoration: underline;
    color: var(--light-text);
}


.footer:hover {
    background-color: var(--black);
    opacity: 1;
    
}

@media only screen and (max-width: 760px) {
    .footer {
        flex-direction: column;
        left: 2rem;
        gap: 0;
        bottom: 1rem;
    }
    
}
