

a {
    text-decoration: none;
}

.main {
    display: flex;
    align-items: center;
    position: relative;
    height: 100vh;
    background-image: url(../../../public/background.png);
    background-repeat: no-repeat;
    background-size: cover;
    overflow-x: hidden;
    min-width: 720px;
    min-height: 800px;
}

.cover {
    position: absolute;
    /* https://cssgradient.io/ <- love this */
    background: rgb(4,0,13);
    background: linear-gradient(0deg, rgba(4,0,13,1) 0%, rgba(36,36,36,0.8848331568955707) 100%);
    /* https://bennettfeely.com/clippy/ <- love this */
    clip-path: polygon(76% 19%, 100% 61%, 100% 100%, 0 100%, 0 0);
    width: 100%;
    min-height: 900px;
    height: 130vh;
    min-width: 950px;
}

.contents {
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 2;
    margin-left: 5%;
    top: 8%;
}

.container {
    padding: 1em 2em 0em 2em;
    background: rgb(4,0,13);
    background: rgb(4,0,13);
    background: rgb(4,0,13);
    background: linear-gradient(0deg, rgba(4,0,13,0.0025316455696202667) 0%, rgba(4,0,13,0.8) 23%, rgba(4,0,13,0.9548611681000525) 51%, rgba(4,0,13,0.918446602273722) 100%);
    border-radius: 2em;
}

.logo-container {
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--light-text);

}

.logo-container img{
    max-width: 10rem;
}

.logo-container div {
    font-family: 'Poppins', sans-serif;
    font-size: 9em;
    font-weight: 700;
}

p {
    margin: 0;
}

.slogan {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.slogan :nth-child(1) {
    color: var(--secondary);
    font-size: 2em;
    text-decoration: u;
}

.slogan :nth-child(2) {
    font-size: 1.2em;
}

.features {
    
    display: flex;
    align-items: center;
    gap: 2em;
    
    margin-top: 2em;
}

.feature-list {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.feature-list.right {
    font-size: 1.2em;
}

.feature-list.right p {
    display: flex;
    align-items: center;
    height: 40px;
}

.divider {
    width: 2px;
    height: 14rem;
    background-color: var(--secondary);
}

.download-button {
    display: flex;
    justify-content: center;
    align-items: center;
    max-height: 88px;

    background-color: rgba(4,0,13,.2);
    padding: 1em .5em 1em .5em;
    border-radius: 1rem;
    gap: 1em;
    outline: 2px solid var(--light-text);
    transition: box-shadow 250ms, transform 250ms;

    margin-top: 4rem;
}

.download-button:hover {
    -webkit-box-shadow: 5px 5px 50px 18px rgba(255,255,255,0.26); 
    box-shadow: 5px 5px 50px 18px rgba(255,255,255,0.26);
    transform: scale(1.01);
}

.download-button div{
    
    font-family: 'Poppins', sans-serif;
    font-weight: bold;
    font-size: 3em;
}

.seller {
    display: flex;
    flex-direction: column;
    top: 0;
}

  