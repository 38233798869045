
.showcase {
    position: fixed;
    width: clamp(500px, 58vw, 1000px);
    max-width: 1000px;
    right: -1.5rem;
    transition: 250ms;
    bottom: 5rem;
    z-index: 9999;
}

.showcase:hover {
    transform: scale(1.05);
}
.showcase img {
    width: 100%;
    
}
@media only screen and (max-width: 1380px) {
    .showcase {
        right: -16rem
    }

    .showcase:hover {
        right: -1.5rem;
    }
}
@media only screen and (max-width: 986px) {
    .showcase {
        display: none;
    }
}